export default [
  {
    path: "/",
    text: "Accueil",
  },
  {
    path: "/actualites-au-soudan/",
    text: "Actualités au Soudan",
  },
  {
    path: "/politique/",
    text: "Politique",
  },
  {
    path: "/histoire/",
    text: "Histoire",
  },
  {
    path: "/culture/",
    text: "Culture",
  },
  {
    path: "/film/",
    text: "Film",
  },
]
